require("@rails/ujs").start()
require("jquery")
require("@fancyapps/fancybox/dist/jquery.fancybox.js")

import 'bootstrap-icons/font/bootstrap-icons.css'

import "../src/plugins"
import "../src/lib"
import "../src/shared"
import "../src/general"
import "../src/main"


import "controllers"
